import * as t from "../../@types/quotes";
import api from "./api";

type optionsApiType = { key: string; value: any }[];

/**
 * API for config / quotes editor
 */
export const apiStore = {
    ready: api.checkToken(),

    addIssue: async (text: string) => {
        const resp = await api.post("resources?name=issue", { text });
        return resp;
    },
    getCrmDataClient: (key: string) => {
        return api.get<Record<string, string>>(
            `resources?name=crm-data&keyword=${key}`
        );
    },
    getHashClientData: (quote_id: string) => {
        return api.post<{ hash: string }>(`public?quote_id=${quote_id}`, []);
    },
    getQuoteClientData: (hash: string) => {
        return api.get(`public?hash=${hash}`);
    },
    getQuote: async (id: string) => {
        const quote = await api.get<{
            client_name: string;
            project: string;
            quote: t.quotationType;
        }>(`resources?name=quote&id=${id}`);
        return quote;
    },
    getOurClients: () => {
        return api.get<t.OurClient[]>(`resources?name=our-clients`);
    },
    getOurClient: (client_name: string) => {
        return api.get<t.OurClient>(
            `resources?name=our-client&id_name=${client_name}`
        );
    },
    setOurClientData: (client_name: string, clientData: t.clientDataType[]) => {
        return api.post("resources?name=outClientData", {
            clientData,
            client_name,
        });
    },
    setOurClientHistory: (
        client_name: string,
        clientData: t.OurClient["history"]
    ) => {
        return api.post("resources?name=outClientHistory", {
            clientData,
            client_name,
        });
    },
    setNewOurClient: (client_name: string, project_id: string) => {
        return api.post("resources?name=newOutClient", {
            client_name,
            project_id,
        });
    },
    getMigLogs: async () => {
        return api.get<t.logsType[]>(`resources?name=mig-logs`);
    },
    setLog: (body: any, level = "info") => {
        return api.post(`resources?name=logs&level=${level}`, body);
    },
    getLogs: (category = "info", level = "info") => {
        return api.get<t.logsType[]>(
            `resources?name=logs&level=${level}&cat=${category}`
        );
    },
    getOptions: async () => {
        const options = await api.get<optionsApiType>("resources?name=options");
        return !options
            ? {}
            : options.reduce(
                  (prev: Partial<t.resourcesType>, opt) => ({
                      ...prev,
                      [opt.key]: opt.value,
                  }),
                  {}
              );
    },
    getProjects: async () => {
        const projects = await api.get<t.projectType[]>(
            "resources?name=projects"
        );
        return projects || [];
    },
    setProjects: async (data: t.projectType | t.projectType[], del = false) => {
        const resp = await api.post("resources?name=projects", data);
        return resp;
    },
    getPkgs: async () => {
        const pkgs = await api.get<t.pkgType[]>("resources?name=pkgs");
        return pkgs || [];
    },
    setPkgs: async (data?: t.pkgType | t.pkgType[]) => {
        await api.post("resources?name=pkgs", data);
        return await apiStore.getPkgs();
    },
    setExtraPkg: async (data: t.extraPkgs[]) => {
        return await api.post("resources?name=extra-pkgs", data);
    },
    getMods: async () => {
        const mods = await api.get<t.modType[]>("resources?name=mods");
        return mods || [];
    },
    setMods: async (data: t.modType | t.modType[]) => {
        await api.post("resources?name=mods", data);
        return await apiStore.getMods();
    },
    rmMods: async (data?: t.modType) => {
        const resp = await api.delete(`resources?name=mods&id=${data?.id}`);
        return resp;
    },
    rmResource: ({ res, data }: { res: string; data: { id: string } }) => {
        return api.delete(`resources?name=resources&key=${res}&id=${data.id}`);
    },
    getDocument: (id: string | number) => {
        return api.get<t.documentObj[]>(`resources?name=document&id=${id}`);
    },
    getDocuments: async () => {
        const out = await api.get<
            Omit<t.documentObj, "body" | "createdAt" | "updatedAt">[]
        >("resources?name=documents");
        return out;
    },
    setDocument: (d: Partial<t.documentObj>) => {
        return api.post("resources?name=document", d);
    },
    getHistoryQuotes: async ({ user, id }: { user?: string; id?: string }) => {
        const quotes = await api.get<t.historiesType[]>(
            `resources?name=history${user ? `&user=${user}` : ""}${
                id ? `&id=${id}` : ""
            }`
        );
        return quotes;
    },
    getMigrationExecStatus: async () => {
        return (
            await api.get<
                {
                    key: string;
                    value: { quality: boolean; prod: boolean };
                }[]
            >("resources?name=option-mig")
        )?.[0].value;
    },
    setMigrationExecStatus: (quality: boolean, prod: boolean) => {
        return api.post("resources?name=option-mig", { quality, prod });
    },

    getHistoriesActiveQuotes: async () => {
        return api.get<t.historiesType[]>("resources?name=history-actived");
    },
    setQuotation: (quote: {
        client_name: string;
        project: string;
        quote: t.quotationType;
        quote_id: string;
    }) => {
        return api.post("resources?name=quotation", quote);
    },
    getAllHistoryQuotes: async () => {
        const quotes = await api.get<t.historiesType[]>(
            "resources?name=history&all=1"
        );
        return quotes;
    },
    getDefDoc: async (project: string) => {
        return await api.get<
            {
                pkgId: string;
                typeMapping: string;
                mode: string;
                singleMod: string;
            }[]
        >(`resources?name=def-doc&project=${project}`);
    },
    getDbRules: async (project: string, quality: 0 | 1 = 1) => {
        const rules = await api.get<t.rulesDbType[]>(
            `resources?name=rules&project_name=${project}&quality=${quality}`
        );
        return rules;
    },
    getCommitsRel: async () => {
        return api.get<{ commits: t.commitRelType[] }>("db-rules");
    },
    setConsumables: async (data: t.consumablesType | t.consumablesType[]) => {
        const resp = await api.post("resources?name=consumables", data);
        return resp;
    },
    setConsumableDecr: async (data: t.resourcesType["consumableDescr"]) => {
        const resp = await api.post("resources?name=consumableDescr", data);
        return resp;
    },
    setSource_collection: async (
        data: t.resourcesType["source_collection"]
    ) => {
        const resp = await api.post("resources?name=source_collection", data);
        return resp;
    },
    setPackets: async (data: t.consumablesType | t.consumablesType[]) => {
        const resp = await api.post("resources?name=packets", data);
        return resp;
    },
    setFixedPackets: async (data: t.consumablesType | t.consumablesType[]) => {
        const resp = await api.post("resources?name=fixedPackets", data);
        return resp;
    },
    updateClientData: (quote_id: string) => {
        return api.post("resources?name=updateClientData", { quote_id });
    },
    set: (resource: string) => {
        const [first, ...str] = resource;
        const key: keyof typeof apiStore | undefined = `set${
            first.toUpperCase() + str.join("")
        }` as keyof typeof apiStore;

        return apiStore[key];
    },
    rm: (resource: string) => {
        const [first, ...str] = resource;
        const key: keyof typeof apiStore | undefined = `rm${
            first.toUpperCase() + str.join("")
        }` as keyof typeof apiStore;
        return apiStore[key];
    },
    downloadPdf: async (store: Partial<t.quotationType>, mod?: string) => {
        const resp = await api.post<{ pdf: string; quoteId: string }>(
            `pdf?mod=${mod}`,
            store
        );
        return resp;
    },
    downloadPdfDataReq: async (project_id: string, quote_id: string) => {
        return api.get<{ pdf: string }>(
            `pdf?project_id=${project_id}&quote_id=${quote_id}`
        );
    },
};

/**
 * API for Defines editor
 */
export const apiProd = {
    ready: api.checkToken(),
    quality: true,
    setQuality: (q: boolean) => {
        apiProd.quality = q;
    },
    generateDef: async (
        quote_id: string,
        new_client: string,
        extra: { mods: any; admin_db?: string },
        new_db?: boolean
    ) => {
        const quote = await api.post<{ quote_id: number; user_db: string }>(
            "resources?name=publishQuote",
            {
                quote_id,
                new_client,
                extra,
                new_db,
            }
        );
        return quote;
    },
    publishClientDB: (
        quote_id: string,
        new_client: string,
        extra: { mods: any }
    ) => {
        api.post("resources?name=publishClientDB", {
            quote_id,
            new_client,
            extra,
        });
    },
    copyInProd: async (sw: string, client: string, e_mods?: string[]) => {
        const resp = await api.post(`defines?name=deploy&q=true`, {
            sw,
            client,
            e_mods,
        });
        return resp;
    },

    switchDef: async (
        sw: string,
        client: string,
        mod: string,
        newTypemod: string
    ) => {
        const resp = await api.post(
            `defines?name=switch${apiProd.quality ? "&q=true" : ""}`,
            {
                sw,
                client,
                mod,
                newTypemod,
            }
        );
        return resp;
    },

    getModsListByTemplateClient: async (clientName: string) => {
        const resp = await api.get<t.modsType[]>(
            `defines?name=mods-by-client&client=${clientName}`
        );
        return resp;
    },

    getClients: async () => {
        const resp = await api.get<{ client: string; software: string }[]>(
            `defines?name=clients${apiProd.quality ? "&q=true" : ""}`
        );
        return resp;
    },
    getModsList: async (sw: string) => {
        const resp = await api.get<t.modsType[]>(
            `defines?name=mods-list&sw=${sw}${apiProd.quality ? "&q=true" : ""}`
        );
        return resp;
    },
    getSummarydiff: async (from: string) => {
        const resp = await api.get<
            (t.modDiffType & { mod_type_q: string; mod_type_p: string })[]
        >(`defines?name=summarydiff&from=${from}`);
        return resp;
    },
    getModsDocs: async () => {
        const resp = await api.get<string>(`defines?name=export`);
        return resp;
    },
    getGlobalClient: (sw: string) => {
        return api.get<{ client: string }[]>(
            `defines?name=global-client&sw=${sw}`
        );
    },
    getMods: async (sw: string, client: string) => {
        const resp = await api.get<
            t.modsType[] | { error: string[]; data: t.modsType[] }
        >(
            `defines?name=mods&sw=${sw}&client=${client}${
                apiProd.quality ? "&q=true" : ""
            }`
        );
        return resp;
    },
    setMod: async (
        sw: string,
        data: {
            client: string;
            mod_type: string;
            mod: string;
            value: string;
        },
        overwrite?: boolean,
        someFilter?: { clientFilter: string[]; filterType: boolean }
    ) => {
        const resp = await api.post(
            `defines?sw=${sw}${apiProd.quality ? "&q=true" : ""}`,
            {
                data,
                overwrite,
                someFilter,
            }
        );
        return resp;
    },
    cloneMod: async (sw: string, from_client: string, new_client: string) => {
        const resp = await api.post(
            `defines?sw=${sw}${apiProd.quality ? "&q=true" : ""}`,
            {
                clone: 1,
                new_client,
                from_client,
            }
        );
        return resp;
    },
    delMods: async (sw: string, client: string) => {
        const resp = await api.delete(
            `defines?sw=${sw}${apiProd.quality ? "&q=true" : ""}`,
            {
                data: { client },
            }
        );
        return resp;
    },
    delMod: async (
        sw: string,
        data: { client?: string; mod_type: string; mod: string }
    ) => {
        const resp = await api.delete(
            `defines?sw=${sw}${apiProd.quality ? "&q=true" : ""}`,
            {
                data,
            }
        );
        return resp;
    },
};

/**
 * Api for CSS-Editor
 */
export const ApiCss = {
    ready: api.checkToken(),
    upload: async (file: FormData, name: string) => {
        const resp = await api.upload(`css-data?name=${name}`, file);
        return resp;
    },
    importZip: async (body: FormData) => {
        const resp = await api.upload(`css-data?name=import-zip`, body);
        return resp;
    },
    getListTemplateZip: async () => {
        return await api.get<string[]>("css-data?name=templateZipStruct");
    },
    getStructZip: async (template: string, css: Record<string, string>) => {
        return await api.post<string>("css-data?name=generate-zip", {
            template,
            css,
        });
    },
    getListStylesheet: async (all = false) => {
        return await api.get<t.cssDataType[]>(
            `css-data?name=listcss${all ? "&user=all" : ""}`
        );
    },
    getImages: async (name: string) => {
        return await api.get(`css-data?name=loadS3imgs&css=${name}`);
    },
    setStyleSheet: async (data: Record<string, string>, name: string) => {
        return await api.post(`css-data?name=save-css`, { css: data, name });
    },
};

/**
 * API databases page
 */
export const ApiDB = {
    ready: api.checkToken(),
    getStructs: async (old = false) => {
        return await api.get<t.dbStructType[]>(
            `db-manager?name=struct${old ? "_old" : ""}`
        );
    },

    getMigrations: async (all = false) => {
        return await api.get<t.migrationStoreType[]>(
            `db-manager?name=migrations${all ? "&all=1" : ""}`
        );
    },

    getMigrationsHash: async () => {
        return await api.get<t.MigsHash[]>(`db-manager?name=migrations-hash`);
    },

    setStruct: async (schema: string, limit_date: string) => {
        return await api.post<{ struct: t.dbStructType; resp: any }>(
            `db-manager?name=consolidatemig`,
            {
                schema,
                limit_date,
            }
        );
    },

    setMigration: async ({
        migrations,
        descr,
    }: {
        migrations: t.migrationType;
        descr: string;
    }) => {
        return await api.post("db-manager?name=migration", {
            migrations,
            descr,
        });
    },
    publishCommits: (data: {
        schema: string;
        hash: string;
        migs: t.commitRelType["sql"];
        descr: string;
    }) => {
        return api.post("db-manager?name=publishCommit", data);
    },
    /**
     * execute migrations in quality
     * @param hash last hash (apply prev migration)
     * @returns result
     */
    applyQuality: async (hash: string, dir = "up") => {
        return await api.post("db-manager?name=applymig&q", { hash, dir });
    },
    /**
     * execute migrations in prod
     * @param hash last hash (apply prev migration)
     * @returns result
     */
    applyProduction: async (hash: string, dir = "up") => {
        return await api.post("db-manager?name=applymig", { hash, dir });
    },
    deleteMigration: async (hash: string) => {
        return await api.delete(`db-manager?hash=${hash}`);
    },
    tableNote: async (schema: string, table: string, note: string) => {
        return await api.put("db-manager?name=table-note", {
            schema,
            table,
            note,
        });
    },
    tableOrder: async (schema: string, table: string, columns: string[]) => {
        return await api.put("db-manager?name=table-order", {
            schema,
            table,
            columns,
        });
    },
};

/**
 * API releases page
 */
export const ApiRel = {
    ready: api.checkToken(),
    getHistory: () => {
        return api.get<string[]>(`release?history`);
    },
    getRel: () => {
        return api.get<t.relType>(`release`);
    },
    setStatus: (s: t.relType["status"]) => {
        return api.post("release?status", s);
    },
    setData: (s: t.relType) => {
        return api.post("release", s);
    },
    getDocFile(name: string) {
        return api.get<t.documentType>(`resources?name=doc&filename=${name}`);
    },
    setDocFile(file: string, content: string) {
        return api.post("resources?name=doc", { file, content });
    },
};

export const ApiPublic = {
    upload: async (
        file: FormData,
        name: string,
        quote_id: string,
        token: string
    ) => {
        const resp = await api.upload(
            `public?name=upload_img&filename=${name}&quote_id=${quote_id}`,
            file,
            token
        );
        return resp as { filename: string };
    },
    update_quote: async (quote_id: number, token: string, quote: any) => {
        const resp = await api.post(
            `public?name=update_quote&quote_id=${quote_id}`,
            { quote },
            token
        );
    },
    dataTaskClient: (client_name: string) => {
        return api.get(
            `public?name=task-data-client&client_name=${client_name}`
        );
    },
};
