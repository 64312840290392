import axios, { AxiosInstance } from "axios";
import { toast } from "react-toastify";

const TESTING = process.env.NEXT_PUBLIC_TESTING === "1";
class Api {
    public api: AxiosInstance;
    private token: string | undefined;
    constructor() {
        this.api = axios.create({
            baseURL: "/api/",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    checkToken() {
        return Boolean(this.token);
    }
    setToken = (token: string) => {
        if (!token) {
            const strUserData = sessionStorage.getItem("userStore");
            const userData = strUserData ? JSON.parse(strUserData) : token;
            if (userData?.idToken) token = userData.idToken;
            else console.log("Error token in session", userData);
        }
        this.token = token;
        this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    };

    async get<T>(url: string) {
        let rip = 20;
        try {
            while (!TESTING && !this.token) {
                await new Promise((res) => setTimeout(res, 100));
                if (rip-- === 0) throw "token not found";
            }
            const resp = await this.api.get(url);
            if (resp.data.error) throw resp.data.error;
            return resp.data as T;
        } catch (e: any) {
            console.error("Api GET error", e);
            toast.warn("api GET error");
            if (
                e?.response &&
                e.response.status === 401 &&
                e.response.statusText === "Unauthorized"
            )
                toast.error("Error, please execute logout e login");
            //console.log('location.href = "/"');
        }
    }
    async post<T>(url: string, data: any, token?: string) {
        if (TESTING) {
        }
        try {
            const resp = await this.api.post(
                url,
                data,
                token
                    ? {
                          headers: { token },
                      }
                    : {}
            );
            if (resp.data.error)
                throw {
                    response: { status: 501, statisText: "api return error" },
                };
            toast.success("Saved");
            return resp.data as T;
        } catch (e: any) {
            console.error("Api POST error", e);
            toast.warn("api POST error");
            if (
                e.response.status === 401 &&
                e.response.statusText === "Unauthorized"
            )
                location.href = "/";
        }
    }

    async put<T>(url: string, data: any) {
        try {
            const resp = await this.api.put(url, data);
            if (resp.data.error) throw "api return error";
            toast.success("Saved");
            return resp.data as T;
        } catch (e) {
            console.error("Api POST error", e);
            toast.warn("api POST error");
        }
    }

    async delete(url: string, data?: any) {
        try {
            const resp = await this.api.delete(url, data);
            if (resp.data.error) throw "api return error";
            toast.success("Deleted");
            return resp.data;
        } catch (e) {
            console.error("Api DELETE error", e);
            toast.warn("api DELETE error");
        }
    }

    async upload(url: string, file: FormData, token?: string) {
        try {
            const resp = await this.api.post(url, file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    token,
                },
            });
            toast.success("Uploaded");
            return resp.data;
        } catch (e) {
            console.error("Api POST/UPLOAD error", e);
            toast.warn("api UPLOAD error");
        }
    }
}

const apiObj = new Api();
export default apiObj;
