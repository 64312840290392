import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "../lib/client/msal";
import { usePathname } from "next/navigation";
import type { AppProps } from "next/app";
import NavBar from "../components/navbar";
import Head from "next/head";
import Footer from "../components/footer";
import { ToastContainer } from "react-toastify";
import { wrapper } from "../lib/client/store/store";
import { Provider } from "react-redux";
import localFont from "next/font/local";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/globals.css";

const myFont = localFont({
    src: [
        {
            path: "../public/fonts/VictorMono-Regular.woff2",
            weight: "400",
        },
        {
            path: "../public/fonts/VictorMono-Bold.woff2",
            weight: "700",
        },
        {
            path: "../public/fonts/VictorMono-Light.woff2",
            weight: "200",
        },
    ],
    variable: "--font-victorMono",
});

export const TESTING = process.env.NEXT_PUBLIC_TESTING === "1";

function ConfiguratorApp({ Component, pageProps }: AppProps) {
    const { store } = wrapper.useWrappedStore(pageProps);
    const pathname = usePathname();
    const showNavbar = () => {
        return !pathname.includes("reseller");
    };
    //console.log({ pathname });
    return (
        <Provider store={store}>
            <Head>
                <title>Arca24 configurator</title>
                <meta name="description" content="Arca24 configurator" />
                <link rel="icon" href="/favicon.ico" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <MsalProvider instance={msalInstance}>
                <div
                    className={`min-h-screen pb-20 ${myFont.variable} font-sans text-[0.7rem]`}
                >
                    {showNavbar() && <NavBar />}
                    <Component {...pageProps} />
                </div>
                <Footer />
            </MsalProvider>
            <ToastContainer />
        </Provider>
    );
}

export default ConfiguratorApp;
